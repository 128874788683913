import { type FC } from 'react';
import { cn } from '@/lib/classNames';
import { TagHarnessSelectors } from '@/components/ui/Tag/Tag.harness';
import {
  TagColor,
  TagMode,
  type TagProps,
  TagSize,
} from '@/components/ui/Tag/Tag.typedefs';
import styles from './Tag.module.scss';

type FCTagType = FC<TagProps> & {
  color: typeof TagColor;
  size: typeof TagSize;
  mode: typeof TagMode;
};

/**
 * *Component:* Tag
 *
 * *Category:* Platform common
 *
 * *Defaults:*
 * - `size`: `TagSize.Medium`
 * - `color`: `TagColor.Default`
 * - `mode`: `TagMode.Subtle`
 * - `dataQa`: `TagHarnessSelectors.DataQa`
 *
 * Tag component is used to display a tag with an icon and text. It can be used in different modes and sizes.
 *
 * **Modifying the component is strictly prohibited. If you need to change the component, please contact the design team first.**
 *
 * @see https://www.figma.com/design/MOGimKFI9BGMIWjXeXkebt/Course-page?node-id=3327-81254&t=SCnZYefG6iUvEzRA-4
 */
export const Tag: FCTagType = (props) => {
  const {
    IconElement,
    text,
    size = TagSize.Medium,
    color = TagColor.Default,
    mode = TagMode.Subtle,
    className,
    dataQa = TagHarnessSelectors.DataQa,
    ...rest
  } = props;

  return (
    <div
      {...rest}
      className={cn(
        styles.container,
        styles[`container--size-${size}`],
        styles[`container--color-${color}-${mode}`],
        className,
      )}
      data-qa={dataQa}
    >
      {IconElement && (
        <IconElement className={styles.icon} />
      )}

      {Boolean(text) && (
        <p className={styles.text}>
          {text}
        </p>
      )}
    </div>
  );
};

Tag.color = TagColor;
Tag.size = TagSize;
Tag.mode = TagMode;
